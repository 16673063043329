<template>
  <div class="billboard">
  </div>
  <div class="form">
     <img :src="logo"/>
      <a href="#" v-if="showBackButton" @click="back" class="d-block text-left mt-3">
        <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />

     </a>
     <h6 class="mt-4 mb-4">{{currentScreen.title}}
       <br v-if="currentScreen.subtitle"/><small v-if="currentScreen.subtitle">{{currentScreen.subtitle}}</small>
     </h6>
    
     <Login v-if="selected == 1" 
            @changeScreen="changeScreen"
            :userName="userName"
            :password="password"/>
     <Forgot v-if="selected == 2" @back="back"/>
     <Recovery v-if="selected == 7" @back="back"/>
     <PreferencesPerson 
      v-if="selected == 3" 
      @changeScreen="changeScreen"
      buttonText="Próximo"
      emiter="savePerson"
      @savePerson="savePerson"
      :newUser="newUser"/>
      <PreferencesProfile
        v-if="selected == 4" 
        @changeScreen="changeScreen"
        buttonText="Próximo"
        emiter="saveProfile"
        @saveProfile="saveProfile"
        :newUser="newUser"
      />
      <SignUpPassword
        v-if="selected == 5" 
        @changeScreen="changeScreen"
        buttonText="Enviar"
        emiter="enviar"
        @enviar="enviar"
        :newUser="newUser"
        :sending="sending"
      />
  </div>
  <footer>
    <div>
      <a href="https://repasys.com.br" target="_blank" class="link">
        Sistema de repasse Repasys
      </a>
    </div>
    <div class="copyright">
      Copyright © 2014-{{year}} FC Code
    </div>
  </footer>
</template>

<style scoped>
  body{
    background: var(--background);
  }
  .form img{
    height: 50px;
  }
  footer{
    border-top: .0625rem solid rgba(0,0,0,.1);
    background: var(--white);
    height: 5.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  footer .copyright{
    font-size: .75rem;
    color: rgba(0,0,0,.45);
  }
  footer .link {
    font-size: .875rem;
    color: rgba(0,0,0,.8)!important;
  }
  .billboard{
    background-color: var(--secondary-color);
    display: block;
    height: 14.875rem;
    width: 100%;
  }
  .form{
    box-shadow: 0 0.0625rem 0.125rem 0 rgb(0 0 0 / 15%);
    margin: -6.875rem auto 0;
    max-width: 26.5rem;
    min-height: 25.5625rem;
    padding: 2rem 4rem;
    border-radius: 4px;
    background-color: var(--white);
    margin-bottom: 6rem;
    text-align: center;
  }
  
  h6{
    font-weight: 600;
  }

  .text-left{
    text-align: left
  }
  small{
    font-size: 70%;
    color: var(--regular)
  }
  @media (max-width: 992px) {
    .form{
      padding: 1rem 2rem;
    }
  }
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt,faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { companyPath, cadastro, logout } from '@/services/auth';
import Login from '@/views/Login.vue';
import Forgot from '@/views/Forgot.vue';
import PreferencesPerson from '@/views/PreferencesPerson.vue';
import PreferencesProfile from '@/views/PreferencesProfile.vue';
import SignUpPassword from '@/views/SignUpPassword.vue';
import Recovery from '@/views/Recovery.vue';

library.add(faSyncAlt,faArrowLeft);

export default {
  name: 'External',
  components: {
    Login,
    Forgot,
    PreferencesPerson,
    PreferencesProfile,
    Recovery,
    SignUpPassword
  },
  data(){
    return {
      newUser: {},
      selected: 1,
      year: new Date().getFullYear(),
      sending: false,
      screens: [
        {
          id: 1,
          title: 'Faça o login ou solicite um cadastro',
        },
        {
          id: 2,
          title: 'Informe seu e-mail',
          subtitle: 'Você reberá as instruções para recuperar a senha'
        },
        {
          id: 3,
          title: 'Informe seus dados',
          subtitle: 'Loja ou pessoa física'
        },
        {
          id: 4,
          title: 'Informe seu perfil de compra',
          subtitle: 'Dados referente aos veículos que você e sua loja tem preferência para revender'
        },
        {
          id: 5,
          title: 'Informe sua senha',
          subtitle: 'Seus dados de acesso ao sistema'
        },
        {
          id: 6,
          title: 'Sucesso!',
          subtitle: 'Você poderá entrar no aplicativo, porém para visualizar os veículos será necessário aguardar a aprovação de seu cadastro'
        },
        {
          id: 7,
          title: 'Redefinição de senha!',
          subtitle: 'Digite uma senha de no mínimo 6 digitos'
        }
      ],
      userName: '',
      password: ''
    }
  },
  computed:{
    currentScreen() {
        return this.screens.filter(s=>s.id === this.selected)[0];
      },
    showBackButton(){
      return [2,3,4,5].includes(this.selected);
    }
  },
  methods: {
    
    changeScreen(id) {
      this.selected = id;
    },
    back() {
      switch(this.selected) {
        case 4:
        case 5:
          this.selected--;
          break;
        
        default: this.selected = 1;
      }
      
    },
    savePerson() {
      this.changeScreen(4);
    },
    saveProfile(){
      this.changeScreen(5);
    },
    enviar() {
      debugger;
      this.sending = true;
      let userData = {
          ...this.newUser,...{ kmAte: parseInt(this.newUser.kmAte),
                          anoAte: parseInt(this.newUser.anoAte), 
                          valorDe: parseInt(this.newUser.valorDe), 
                          valorAte: parseInt(this.newUser.valorAte)}
        };
      console.log(userData);
      cadastro(userData).then(response => {
        const { data, status, statusText } = response;
        if(status == 201) {
          alert('Cadastro realizado com sucesso!\n\nAguarde a aprovação de nossa equipe.');
          debugger;
          this.userName = userData.email;
          this.password =  userData.senha;

          this.selected = 1;
        }
      }).catch(err => {
        const { data, status, statusText } = err.response;
        let errorText = 'Erro no envio do cadastro, por favor tente mais tarde.';
        if(data?.errors?.length)
            errorText = data?.errors[0]?.detail;            
        
        alert(errorText);
      }).finally(() => {
        this.sending = false;
      })
    }
  },
  beforeMount() {
    this.logo = `${location.protocol}//${companyPath}.repasys.com.br/Content/img/logo.png`;
    logout();
    this.$root.user = null;
    this.$root.authenticated = false;
    localStorage.clear();

  },
  mounted(){
     let id = this.$route.params['id'];
     if(id) {
       this.selected = 7;
     }
  }
}
</script>