<template>
  <form @submit.prevent="entrar">
    <input type="text" v-model="usuario"  autocomplete="username"  class="form-control form-control-lg mt-3" placeholder="E-mail"/> 
    <input type="password" v-model="senha"  autocomplete="current-password" class="form-control form-control-lg mt-3" placeholder="Senha"/> 
    <div class="options mt-3">
      <div class="form-check">
      <input class="form-check-input" type="checkbox" v-model="remember" value="true" id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        Lembrar
      </label>
    </div>
    <a href="#" @click="forgot">Esqueci minha senha</a>
    </div>
    <div class="text-danger mt-3">
      {{error}}
    </div>
    <div class="d-grid gap-2 mt-4">
    <button class="btn btn-primary" :disabled="loading" type="submit" @submit="entrar">
        <span v-if="!loading">Entrar</span>
        <font-awesome-icon :icon="['fas', 'sync-alt']" spin 
        v-if="loading"/>
    </button>
    <button class="btn btn-primary-outline"  type="button"
            @click="$emit('changeScreen',3)">Criar conta</button>
  </div>
  </form>
</template>

<style scoped>
  .options{
    display: flex;
    justify-content:space-between;
    font-size: 0.85rem;
  }
  .text-danger{
    font-weight: 500
  }
  .form-control-lg {
    font-size: 1rem;
  }
  
  
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import {login,companyPath} from '@/services/auth';
import {getItemOrFetch,setItem} from '@/services/localdb';
import {personKey,empresaKey} from '@/services/util';


library.add(faSyncAlt);

export default {
  name: 'Login',
  props: {
    userName: String,
    password: String
  },
  data(){
    return {
      logo: '',
      usuario: '',
      senha: '',
      loading: false,
      error: '',
      remember: true,
      screens: [
        {
          id: 1,
          title: 'Faça o login ou solicite um cadastro',
        }
      ]
    }
  },
  methods: {
    entrar($event) {
      if(!this.senha || !this.usuario) {
        this.error = 'Usuário ou senha incorretos.';
        return;
      }

      let user = {
        usuario: this.usuario,
        senha: this.senha,
        remember: this.remember
      }

      this.loading = true;
      login(user)
          .then(async data => {
              if (!data || data.isAxiosError === true || !data.tokenAcesso) {
                  this.error = 'Usuário ou senha incorretos.';
              }
              else {
                  let userData = await getItemOrFetch(personKey, '/v1/lojistas');
                  data = {...data,...userData};

                  let company = await getItemOrFetch(empresaKey, '/v1/empresas');

                  this.$root.user = data;
                  this.$root.company = company.filter(f=>f.matriz)[0];
                  this.$root.company.logoUrl = `${location.protocol}//${companyPath}.repasys.com.br/Content/img/logo.png`;
                  this.$root.company.imagemUrl = `${location.protocol}//${companyPath}.repasys.com.br/Content/img/user.png`;
      
                  this.$root.authenticated = true;
                  setItem('company',JSON.stringify(this.$root.company));
                  this.$router.push(this.$route.query.redirect || '/');
              }
              this.loading = false;
          });    
    },
    forgot() {
      this.$emit("changeScreen", 2)
    },
  },
  beforeMount() {
  },
  mounted(){
    debugger;
     if(this.password && this.userName) {
       this.usuario = this.userName;
       this.senha = this.password;

       this.entrar();
     }
  }
}
</script>